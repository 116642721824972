import React from "react"
import { Link } from "gatsby"

export const Thanks: React.FC = () => {
  return(
    <section className="contact-thanks">
      <h2 className="subtitle text-center">送信完了</h2>
      <div className="contact-thanks-inner">
        <p className="contact-thanks-message">お問い合わせありがとうございました。</p>
        <p className="contact-thanks-message">お問い合わせ内容を承りました。</p>
        <p className="contact-thanks-message">３営業日以内にご連絡致しますので、<br className="d-md-none"/>今しばらくお待ち下さい。</p>
        <Link to="/" className="btn-contact btn-effect mt-5">
          トップページへ戻る
        </Link>
      </div>
    </section>
  )
}
