import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { Row, Col } from "react-bootstrap"
import axios from "axios"

interface Values {
  name?: string | undefined;
  furigana?: string | undefined;
  tel?: string | undefined;
  email?: string | undefined;
  address?: string | undefined;
  request?: string | undefined;
  others?: string | undefined;
  recaptcha_response?: string | undefined;
}

type Props = {
  newToken?: string;
  setIsSubmitSuccessful: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Form: React.FC<Props> = (props) => {

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitError, setIsSubmitError] = useState("");
  const apiUrl: string | undefined = process.env.REACT_APP_API_CONTACTFORM;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const onSubmit = (data: Values) => {
    if (!isSubmitting) {
      setIsSubmitting(true)
      data.recaptcha_response = props.newToken
      axios({
        method: "post",
        url: apiUrl,
        headers: { "content-type": "application/json" },
        data: data,
      })
        .then(result => {
          if (result.data.sent) {
            reset()
            setIsSubmitting(false)
            props.setIsSubmitSuccessful(true)
          } else {
            setIsSubmitting(false)
            setIsSubmitError(result.data.message)
          }
        })
        .catch(() => {
          setIsSubmitting(false)
          setIsSubmitError("接続エラーです。もう一度お試しください。")
        })
    }
  };

  const validateName = () => {
    switch (errors.name?.type) {
      case "required":
        return "お名前を入力してください"
      default:
        return "8文字以内で入力してください"
    }
  };

  const validateFurigana = () => {
    switch (errors.furigana?.type) {
      case "required":
        return "ふりがなを入力してください"
      default:
        return "15文字以内で入力してください"
    }
  };

  const validateTel = () => {
    switch (errors.tel?.type) {
      case "required":
        return "電話番号を入力してください"
      case "maxLength":
        return "11文字以内で入力してください"
      default:
        return "電話番号は正しい形式で入力してください"
    }
  };

  const validateEmail = () => {
    switch (errors.email?.type) {
      case "required":
        return "メールアドレスを入力してください"
      case "maxLength":
        return "30文字以内で入力してください"
      default:
        return "メールアドレスは正しい形式で入力してください"
    }
  };

  const validateAddress = () => {
    switch (errors.address?.type) {
      case "required":
        return "住所を入力してください"
      default:
        return "50文字以内で入力してください"
    }
  };

  const validateRequest = () => {
    if (errors.request?.type === "required") {
      return "ご用件を選択してください"
    }
  };

  const validateOthers = () => {
    if (errors.others?.type === "maxLength") {
      return "200文字以内で入力してください"
    }
  };

  const showSubmitMessage = () => {
    if (isSubmitting) {
      return <p className="form-submit-message">送信中です…</p>
    } else if (isSubmitError !== "") {
      return <p className="form-submit-message submit-error">{isSubmitError}</p>
    }
  };

  return (
    <form
      className="contact-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Row className="form-group">
        <label
          className="col-lg-4 col-form-label"
          htmlFor="name"
        >
          お名前<span className="form-required pl-2">必須</span>
        </label>
        <Col lg={8}>
          <input
            className="form-control"
            type="text"
            id="name"
            placeholder="山田太郎"
            {...register("name", { required: true, maxLength: 8 })}
          />
          {errors.name?.type && (
            <p className="form-error-message">{validateName()}</p>
          )}
        </Col>
      </Row>
      <Row className="form-group mt-3">
        <label
          className="col-lg-4 col-form-label"
          htmlFor="furigana"
        >
          ふりがな<span className="form-required pl-2">必須</span>
        </label>
        <Col lg={8}>
          <input
            className="form-control"
            type="text"
            id="furigana"
            placeholder="やまだたろう"
            {...register("furigana", {
              required: true,
              maxLength: 15,
            })}
          />
          {errors.furigana?.type && (
            <p className="form-error-message">
              {validateFurigana()}
            </p>
          )}
        </Col>
      </Row>
      <Row className="form-group mt-3">
        <label className="col-lg-4 col-form-label" htmlFor="tel">
          電話番号（ハイフン無し）
          <span className="form-required ml-0">必須</span>
        </label>
        <Col lg={8}>
          <input
            className="form-control"
            type="tel"
            id="tel"
            placeholder="0544245474"
            {...register("tel", {
              required: true,
              maxLength: 11,
              pattern: /^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/i,
            })}
          />
          {errors.tel?.type && (
            <p className="form-error-message">{validateTel()}</p>
          )}
        </Col>
      </Row>
      <Row className="form-group mt-3">
        <label className="col-lg-4 col-form-label" htmlFor="email">
          メールアドレス（半角）
          <span className="form-required ml-0">必須</span>
        </label>
        <Col lg={8}>
          <input
            className="form-control"
            type="text"
            id="email"
            placeholder="name@example.com"
            {...register("email", {
              required: true,
              maxLength: 30,
              pattern:
                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/i,
            })}
          />
          {errors.email?.type && (
            <p className="form-error-message">{validateEmail()}</p>
          )}
        </Col>
      </Row>
      <Row className="form-group mt-3">
        <label
          className="col-lg-4 col-form-label"
          htmlFor="address"
        >
          住所<span className="form-required">必須</span>
        </label>
        <Col lg={8}>
          <input
            className="form-control"
            type="text"
            id="address"
            maxLength={50}
            placeholder="静岡県富士宮市小泉2385-3"
            {...register("address", {
              required: true,
              maxLength: 50,
            })}
          />
          {errors.address?.type && (
            <p className="form-error-message">
              {validateAddress()}
            </p>
          )}
        </Col>
      </Row>
      <Row className="form-group mt-3">
        <div className="col-lg-4 col-form-label">
          ご用件
          <span className="form-required pl-2">必須</span>
        </div>
        <Col lg={8}>
          <select
            className="form-select"
            {...register("request", { required: true })}
          >
            <option value="" hidden>
              選択してください
            </option>
            <option value="雨漏り相談">雨漏り相談</option>
            <option value="シーリング工事">シーリング工事</option>
            <option value="防水工事">防水工事</option>
            <option value="その他">その他</option>
          </select>
          {errors.request?.type && (
            <p className="form-error-message">
              {validateRequest()}
            </p>
          )}
        </Col>
      </Row>
      <Row className="form-group mt-3">
        <label className="col-lg-4 col-form-label" htmlFor="others">
          その他・ご要望
        </label>
        <Col lg={8}>
          <textarea
            className="form-control"
            id="others"
            rows={3}
            {...register("others", { maxLength: 200 })}
          />
          {errors.others?.type && (
            <p className="form-error-message">{validateOthers()}</p>
          )}
        </Col>
      </Row>
      <div className="form-group mt-3">
        <button
          type="submit"
          value="submit"
          className="btn-contact btn-effect"
        >
          送信する
        </button>
      </div>
      {showSubmitMessage()}
    </form>
  )
}
