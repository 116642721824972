import React, { useState, useCallback, useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3"
import AnchorLink from 'react-anchor-link-smooth-scroll';

import Seo from "../components/Seo"
import { Layout } from "../components/Layout"
import { Form } from "../components/Form"
import { Thanks } from "../components/Thanks"

import imgTel from "../images/header-tel.svg"

const ContactPage: React.FC = () => {
  const pageName: string = "お問い合わせ";

  const [token, setToken] = useState("");
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
  let newToken: string = token;

  const handleVerify = useCallback(token => { setToken(token)},[setToken]);

  return (
    <Layout pageName={pageName}>
      <Seo title={`${pageName} | メープル・シーリング`} />
      <GoogleReCaptchaProvider reCaptchaKey="6LcoDJAUAAAAAFMnQnAia8iuwYSGfrqh9xneKTcj" language="ja">
      <Container>
        {!isSubmitSuccessful ? (
          <>
            <section>
              <Row>
                <Col xs={12}>
                  <h3 className="subtitle">ご相談窓口</h3>
                </Col>
                <Col md={7} lg={8}>
                  <p>お問い合わせは、下記のフォームから送信してください。</p>
                  <p>お急ぎの方はお電話でお問い合わせください。</p>
                  <img
                    className="contact-img-tel mt-2"
                    src={imgTel}
                    alt="代表直通TEL"
                  />
                </Col>
                <div className="col-md-5 col-lg-4">
                  <StaticImage
                    src="../images/contact.jpg"
                    alt="ご相談・お見積り無料"
                  />
                  <a
                    href="tel:090-1295-8300"
                    className="btn-call-sp btn-effect"
                  >
                    タップして発信
                  </a>
                </div>
              </Row>
            </section>
            <section id="contactform">
              <Row>
                <Col xs={12}>
                  <h3 className="subtitle">お問い合わせフォーム</h3>
                  <p>下記フォームに必要事項を入力後、「送信」ボタンを押してください。<br />後日当社よりお電話にてご連絡致します。
                  </p>
                  <p>お問い合わせいただく内容及びお客様の個人情報は、当社の
                    <AnchorLink className="text-link" href="#privacy">プライバシーポリシー</AnchorLink>
                    に則り管理いたします。
                  </p>
                </Col>
              </Row>
              <Form newToken={newToken} setIsSubmitSuccessful={setIsSubmitSuccessful}/>
            </section>
            <section id="privacy">
              <Row>
                <div className="col-12 mb-4">
                  <h3 className="subtitle">プライバシーポリシー</h3>
                  <p>
                    当社では、個人情報の取り扱いにつき、業務実態に応じた個人情報保護のための管理体制を確立するとともに、適切かつ慎重に取り扱い致します。
                  </p>
                  <p>
                    1) 個人情報の取り扱いについて
                    <br />
                    個人情報を収集する際は、適切な範囲で適法かつ公正な手段で個人情報を収集いたします。
                    <br />
                    当社では次のような場合に必要な範囲で個人情報を収集する場合がございます。
                  </p>
                  <p>
                    1 . 当社の業務内容についてのお問い合わせ
                    <br />2 . 工事に関するご相談・お見積り
                    <br />3 . その他お問い合わせ
                  </p>
                </div>
              </Row>
            </section>
          </>
        ) : (
          <Thanks />
        )}
        </Container>
        <GoogleReCaptcha onVerify={handleVerify} />
      </GoogleReCaptchaProvider>
    </Layout>
  )
}

export default ContactPage
